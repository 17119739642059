.wizard {
  $root: &;

  @include p(xxl);

  display: flex;
  height: 100%;
  flex-direction: column;

  @media screen and (height <= 770px) {
    @include p(0);
  }

  /* No margin on first headline */
  > h4:first-child {
    @include mt(0);
  }

  #{&}__wrapper {
    display: flex;
    height: 100%;
  }

  #{&}__navbar {
    @include p(xxl, 0);

    position: relative;
    text-align: right;
    width: 220px;
    height: 100%;
    border-right: 2px solid;

    @include attribute(border-right-color, secondary);

    z-index: 99;

    @include attribute(background-color, primary-static);
    @include attribute-overwrite(background-color, ( dark: primary-dark-static ));

    @media only screen and (width <= 1200px) {
      width: 210px;
    }

    .wizard__element {
      .wizard-element__label {
        @include mb(xs);
        @include p(0, xxl);

        position: relative; // so circle can be absolute
        text-align: right;
        @extend %heading--semi-strong;

        @extend %heading--small;

        @include attribute(color, primary-light);

        &:hover {
          &.sidebar-header--unlocked {
            cursor: pointer;

            .circle {
              transform: scale(0.4);
              box-shadow: 1px 1px 1px $color-secondary;
            }
          }
        }

        .wizard-element__stepnumber {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 45px;
          height: 45px;
          right: -23px;
          top: -10px;
          text-align: center;
          overflow: hidden;

          @include attribute(background-color, secondary);

          box-shadow: 2px 2px 2px $color-secondary;
          border-radius: 100%;
          transform: scale(0.3);
          transition: all 0.2s ease-in-out;
          z-index: 1;

          @include attribute(color, white-static);

          .content {
            opacity: 0;
          }
        }
      }

      &.wizard__element--active {
        .wizard-element__label {
          @include attribute(color, white-static);

          @extend %heading;

          .wizard-element__stepnumber {
            transform: scale(1);
            vertical-align: middle;
            box-shadow: 0 0 $color-secondary;

            .content {
              opacity: 1;
            }
          }
        }

        .wizard-element__subtitle {
          max-height: 1000px;
          overflow: inherit;
          opacity: 1;
        }
      }
    }

    .wizard-element__subtitle {
      @include p(0 , xxl);
      @include attribute(color, primary-light);

      @extend %line-height--text;

      text-align: right;
      overflow: hidden;
      transition: opacity 0.3s ease;
      max-height: 1000px;
      opacity: 1;
    }
  }

  #{&}__text {
    @include m(m, 0, xl);
  }

  #{&}__hint {
    @include m(xl, 0);
  }

  #{&}__content {
    position: relative;
    width: 10px;
    flex-grow: 1;

    @include attribute(background-color, white);

    h4 {
      @include m(0, 0, l);
    }
  }

  #{&}__component {
    height: 100%;

    > :not(div) {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;

      &:not(.wizard__component--frameless) {
        @include p(xxl);
      }
    }
  }

  #{&}__content-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 50px; /* The container grows anyway so the size doesn't matter. */

    #{$root}__header {
      @include mb(l);

      .heading {
        @include m(0);
        @extend %heading--semi-strong;

        @extend %heading;
      }

      .subheading {
        @include m(0);

        @extend %heading;
      }
    }

    #{$root}__scroll-container {
      overflow: auto;
      flex-grow: 1;
    }
  }

  #{&}__element {
    cursor: pointer;

    &.disabled {
      pointer-events: none;
      cursor: text;
    }
  }

  #{&}__button-wrapper {
    width: 100%;
    align-self: flex-end;
  }

  #{&}__button {
    @include p(s, m);

    cursor: pointer;

    &[disabled] {
      opacity: 0.5;
      cursor: default;
    }

    &.next-button {
      @include attribute(color, white-static);
      @include attribute(background-color, secondary);

      float: right;
      border: 1px solid;

      @include attribute(border-color, secondary);

      t23-icon {
        @include ml(xs);
      }

      .icon--button-loader {
        @include ml(xs);

        vertical-align: middle;
      }
    }

    &.prev-button {
      @include attribute(color, primary);

      background-color: transparent;
      border: 1px solid;

      @include attribute(border-color, primary);

      t23-icon {
        @include mr(xs);
      }
    }
  }
}
